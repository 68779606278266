import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import axios from "axios";
import $ from "jquery";
import { AJAROOT } from "@/common/gdata/global.js";
import queryString from "query-string";
import {
  //checkUserLogin,
  MyToast,
  MyToastShow,
  // showStuffTime,
  //getDateFromPeriodno,
  getPeriodnoFromDate,
  // formatDate,
  getUserIndexData,
} from "@/jscript/common.js";
//import TimePicker from 'react-time-picker';
import "@/css/user.css";
import "@/css/react-datepicker.css";
import rjt from "../../../statics/rjt.png";
import DatePicker from "react-datepicker";
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //  myu: "",
      title: "",
      editid: "",
      username: "",

      name: "",
      //  telephone: "",
      usertel: "",
      selectedservice: "",
      serviceid: "",
      servicetext: "",


      fixedemployee: 0,
      remark: "",
      // dianusername: ",",
      // branchlist: [],
      servicelist: [],
      employeelist: [],
      employeeusername: "",
      selectedemployee: "",
      schfromdate: new Date(new Date().setDate(1)),
      schtodate: "",
      postresult: "",
      checkerror: "",
      timepanel: 0,
      mytoast: {
        show: false,
      },
      fixdate: 0,
      fixtime: [], //固定日期每天时间
      avarangedate: [], //范围日期所有天
      colors: [
        { title: "Available Time", color: "#fff" },
        { title: "Booked Time", color: "yellow" },
        { title: "Off Time", color: "#ccc" },
      ],
      colors2: [
        { title: "Available Day", color: "#fff" },
        { title: "No Available", color: "#ccc" },
      ],

      months: [],

      weeks: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      worktimelist: [],
      bookdata: { nickname: "", employee: "", periodno: "", mins: [] },
      savedbookdata: { nickname: "", employee: "", periodno: "", mins: [] },
      oldemployee: "",
      oldperiodno: "",
      oldfromtime: "",
      oldtotime: "",
      selectedday: "",
      schusername: "",
      customerlist: [],

      schservicetitle: "",
      filteredservicelist: [],
      servicelistshow: 0,
      selectedservicecateindex: 0,
      earestandlasttime: [],
      periodno: "",
      fromtime: "11:00",

      hours: [
        /*  "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",     */
      ],
      minutes: ["00", "10", "20", "30", "40", "50"],
      // worktimefrom:"",
      //  worktimeto:""
      minhour: 0,
      maxhour: 0,
      minminute: 0,
      maxminute: 0,
      fromhour: 0,
      fromminute: 0,
      isguest: 0,
      firstname: "",
      lastname: "",
      telephone: "",
      storeid: "",
      haveinsurance: 1,
      needlogout: false,
      submited: false
    };
  }
  render() {
    const { myusername } = this.props;
    //  let menujson = Menu();
    // console.log(menujson);

    //let checkstatus = checkUserLogin(this.props, myusername);

    // if (checkstatus != "") return checkstatus;
    //let times = "";

    if (this.state.editid != "" && this.state.savedbookdata.mins.length == 0) {
      let _fromtime = this.state.savedbookdata.fromtime;

      let _totime = this.state.savedbookdata.totime;
      // if (_fromtime * 1 == 0) _fromtime = "00";
      // if (_totime * 1 == 0) _totime = "00";
      if (typeof _fromtime != "undefined")
        _fromtime = _fromtime.replace(":0", ":00");
      if (typeof _totime != "undefined") _totime = _totime.replace(":0", ":00");
      //  times = _fromtime + " - " + _totime;
    } else if (this.state.savedbookdata.mins.length > 0) {
      //  console.log("bb");

      let _fromtime = this.state.savedbookdata.mins[0];

      let _totime =
        this.state.savedbookdata.mins[this.state.savedbookdata.mins.length - 1];

      if (typeof _fromtime != "undefined")
        _fromtime = _fromtime.replace(":0", ":00");
      if (typeof _totime != "undefined") _totime = _totime.replace(":0", ":00");
      //  times = _fromtime + " - " + _totime;
    }

    let nickname = this.state.savedbookdata.nickname;
    if (nickname != "") nickname = nickname + "，";
    if (this.state.needlogout) {
      return (
        <div
          className="container wrap"
          style={{ height: "100%", paddingBottom: "90px", width: "90%", fontSize: "17px" }}
        >You are not a customer, need logout for visit this page.</div>
      );
    }
    else if (this.state.submited) {
      return (
        <div
          className="container wrap"
          style={{ height: "100%", paddingBottom: "90px", width: "90%", fontSize: "17px" }}
        >{this.state.postresult}</div>
      );
    }
    return (
      <div
        className="container wrap"
        style={{ height: "100%", paddingBottom: "90px", width: "90%" }}
      >
        <div className="pagetitle">
          <div style={{ float: "left" }}>
            <Link to={"/user/book/list"}>Book List</Link>{" "}
            <img src={rjt} style={{ width: "18px", height: "18px" }} />{" "}
            {this.state.title}
          </div>
          <div
            style={{
              float: "right",
            }}
          >
            {this.state.isguest == 0 && (
              <div
                style={{}}
                onClick={() => {
                  window.history.back(-1);
                }}
              >
                <span className="iconfont fanhui">&#xe625;</span>
              </div>
            )}
          </div>
        </div>

        {this.state.isguest == 0 && (
          <div className="row textright clear">
            <div>
              <Link to="/user/book/list">
                <input
                  type="button"
                  name="button1"
                  value="Book List"
                  className="add"
                ></input>
              </Link>
            </div>
          </div>
        )}
        <div className="row">
          <div
            className="col-xs-12 col-sm-12 col-md-12 col-lg-12 form"
            style={{}}
          >
            {this.state.isguest == 0 && (
              <div className={"row"}>
                <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                  * Account:
                </div>
                <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                  {this.props.myusername != "" ? (
                    <div>
                      <span style={{ paddingRight: "10px" }}>
                        {this.props.myusername}
                      </span>
                      {this.state.firstname != "" && (
                        <span style={{ paddingRight: "10px" }}>
                          Name:{" "}
                          {this.state.firstname + " " + this.state.lastname}
                        </span>
                      )}
                      {this.state.telephone != "" && (
                        <span style={{ paddingRight: "10px" }}>
                          Tel: {this.state.telephone}
                        </span>
                      )}
                      <span
                        style={{ paddingRight: "10px", fontSize: "15px" }}
                        onClick={() => this.selCust("", "", "")}
                      ></span>
                    </div>
                  ) : (
                    <div style={{ position: "relative" }}>
                      <div>{this.props.myusername}</div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {this.state.isguest == 1 && (
              <>
                <div className={"row"}>
                  <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                    * First Name:
                  </div>
                  <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                    <input
                      value={this.state.firstname}
                      onChange={(ref) => this.changeField(ref, "firstname")}
                    />
                  </div>
                </div>
                <div className={"row"}>
                  <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                    Last Name:
                  </div>
                  <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                    <input
                      value={this.state.lastname}
                      onChange={(ref) => this.changeField(ref, "lastname")}
                    />
                  </div>
                </div>
                <div className={"row"}>
                  <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                    * Telephone:
                  </div>
                  <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                    <input
                      value={this.state.telephone}
                      onChange={(ref) => this.changeField(ref, "telephone")}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                    Have Insurance:
                  </div>
                  <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                    <input
                      type="checkbox"
                      name="haveinsurance"
                      value="1"
                      onChange={(ref) => this.changeField(ref, "haveinsurance")}
                      checked={this.state.haveinsurance == 1 ? true : false}
                      style={{
                        width: "18px",
                        height: "18px",
                      }}
                    ></input>
                  </div>
                </div>
              </>
            )}
            <div className={"row"}>
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                * Service:
              </div>
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9 ">
                {this.state.serviceid != "" ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        paddingRight: "10px",
                        height: "30px",
                        lineHeight: "30px",
                      }}
                    >
                      {this.state.servicetext}
                    </span>

                    <span
                      className="icon iconfont"
                      style={{
                        paddingRight: "10px",
                        paddingLeft: "15px",
                        fontSize: "19px",
                        textAlign: "right",
                      }}
                      onClick={() => this.selService("", "")}
                    >
                      &#xe658;
                    </span>
                  </div>
                ) : (
                  <div style={{ position: "relative" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <input
                        name="schservice"
                        autoComplete="off"
                        value={this.state.schservicetitle}
                        onChange={(ref) => this.searchService(ref)}
                        placeholder="Search for service"
                        style={{
                          borderTop: "0px",
                          borderLeft: "0px",
                          borderRight: "0px",

                          borderBottomColor: "#aaa",
                          borderBottomWidth: "1px",
                          height: "30px",
                          lineHeight: "30px",
                          width: "200px",
                        }}
                      ></input>

                      <span
                        className="icon iconfont"
                        style={{
                          paddingRight: "10px",
                          paddingLeft: "15px",
                          fontSize: "33px",
                          textAlign: "right",
                          height: "30px",
                          lineHeight: "30px",
                          marginBottom: "15px",
                        }}
                        onClick={() => this.ShowServiceList(1)}
                      >
                        ...
                      </span>
                    </div>

                    {this.state.servicelistshow == 1 ? (
                      <div
                        style={{
                          position: "absolute",
                          width: "450px",
                          height: "500px",
                          border: "1px solid #ddd",
                          backgroundColor: "#ddd",
                          //borderTop: "2px solid #ddd",
                          // borderBottom: "2px solid #ddd",
                          top: -3,
                          left: -8,
                          zIndex: 100,
                          padding: "5px",
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "bold",
                            textAlign: "center",

                            height: "30px",
                            lineHeight: "30px",
                          }}
                        >
                          <div
                            style={{
                              float: "left",
                              width: "90%",
                              textAlign: "center",
                            }}
                          >
                            Select Service
                          </div>
                          <div
                            style={{
                              float: "left",
                              width: "10%",
                              fontSize: "22px",
                            }}
                            onClick={() => this.ShowServiceList(0)}
                          >
                            X
                          </div>
                        </div>
                        <div style={{}}>
                          {this.state.servicelist.map((item, index) => {
                            return (
                              <div
                                key={"schcate" + index}
                                style={{
                                  margin: "5px",
                                  padding: "5px",
                                  float: "left",

                                  background:
                                    this.state.selectedservicecateindex == index
                                      ? "#f6f6f6"
                                      : "#f6f6f6",
                                  fontWeight:
                                    this.state.selectedservicecateindex == index
                                      ? "bold"
                                      : "normal",
                                }}
                                onClick={() =>
                                  this.setState({
                                    selectedservicecateindex: index,
                                  })
                                }
                              >
                                {"" + item.catename + ""}
                              </div>
                            );
                          })}
                        </div>

                        {this.state.servicelist.length > 0 && (
                          <div
                            style={{
                              maxHeight: "400px",
                              minHeight: "100px",
                              overflowY: "auto",
                              clear: "both",
                            }}
                          >
                            {this.state.servicelist[
                              this.state.selectedservicecateindex
                            ].child.map((item, index) => {
                              return (
                                <div
                                  key={"schservice" + index}
                                  style={{ padding: "3px", width: "100%" }}
                                  onClick={() =>
                                    this.selService(item.id, item.title)
                                  }
                                >
                                  {"" + item.title + "　"}
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    ) : (
                      <>
                        {this.state.filteredservicelist.length > 0 && (
                          <div
                            style={{
                              position: "absolute",
                              top: "35px",
                              background: "#f6f6f6",
                              zIndex: 9999,
                              maxHeight: "400px",
                              minHeight: "100px",
                              overflowY: "auto",
                              border: "1px solid #555",
                            }}
                          >
                            {this.state.filteredservicelist.map(
                              (item, index) => {
                                return (
                                  <div
                                    key={"schcustomer" + index}
                                    style={{ padding: "3px" }}
                                    onClick={() =>
                                      this.selService(item.id, item.title)
                                    }
                                  >
                                    {"" + item.title + "　"}
                                  </div>
                                );
                              }
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className={"row"}>
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                * Date:
              </div>
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                <DatePicker
                  selected={this.state.periodno}
                  onChange={(ref) => this.setStartDate(ref)}
                  showYearDropdown
                  dateFormat="yyyy-MM-dd"
                  placeholderText="Input book date"
                />
              </div>
            </div>
            <div className={"row"}>
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                From Time:
              </div>
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                {this.state.worktimefrom}
                {/*  <TimePicker
               onChange={(ref) =>
                                  this.changeTime(
                                    ref
                                  )
                                }
     
        value={this.state.fromtime}
        minuteStep={10}
        
         minTime={this.state.worktimefrom}
        maxTime={this.state.worktimeto}
      /> */}
                <select
                  name="fromhour"
                  style={{ height: "30px", width: "100px" }}
                  onChange={(ref) =>
                    this.changeField(
                      ref,

                      "fromhour"
                    )
                  }
                  value={this.state.fromhour}
                >
                  {this.state.hours.map((item, index) => {
                    return (
                      <option key={"fromhour" + index} value={"" + item}>
                        {item}
                      </option>
                    );
                  })}
                </select>
                <span
                  style={{
                    width: "25px",
                    textAlign: "center",
                    display: "inline-block",
                  }}
                >
                  :
                </span>
                <select
                  name="fromminute"
                  style={{ height: "30px", width: "100px" }}
                  onChange={(ref) => this.changeField(ref, "fromminute")}
                  value={this.state.fromminute}
                >
                  {this.state.minutes.map((item, index) => {
                    let show = true;
                    /*    if(item=="00")
                                {
                                console.log("fromhour"+this.state.fromhour);
                                   console.log("minhour"+this.state.minhour);
                                     console.log("minminute"+this.state.minminute);
                                       console.log("fromminute"+item);
                                } */
                    if (
                      this.state.fromhour * 1 == this.state.minhour * 1 &&
                      this.state.minminute * 1 > item * 1
                    ) {
                      show = false;
                    } else if (
                      this.state.fromhour * 1 == this.state.maxhour * 1 &&
                      this.state.maxminute * 1 < item * 1
                    ) {
                      show = false;
                    }
                    if (!show)
                      return <Fragment key={"fromminute" + index}></Fragment>;
                    else
                      return (
                        <option key={"fromminute" + index} value={"" + item}>
                          {item}
                        </option>
                      );
                  })}
                </select>{" "}
                {/* */}
                {" (" +
                  this.state.minhour +
                  ":" +
                  this.state.minminute +
                  " - " +
                  this.state.maxhour +
                  ":" +
                  this.state.maxminute +
                  ")"}
              </div>
            </div>
            <div className={"row"} style={{ position: "relative" }}>
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                Employee:
              </div>
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9 ">
                <div style={{ float: "left", display: "flex" }}>
                  <select
                    name="employee"
                    style={{
                      height: "30px",
                      width: "150px",
                      marginRight: "6px",
                    }}
                    onChange={(ref) => this.changeField(ref, "employee")}
                    value={this.state.employeeusername}
                  /*    onChange={this.changeService} */
                  >
                    <option value="">Select</option>
                    {this.state.employeelist.map((item, index) => {
                      return (
                        <option
                          key={"employee" + index}
                          value={"" + item.username}
                        >
                          {item.firstname + " "} {item.lastname}
                        </option>
                      );
                    })}
                  </select>

                  {this.state.employeeusername != "" && (
                    <span>
                      Fixed
                      <input
                        type="checkbox"
                        name="fixedemployee"
                        value="1"
                        onChange={(ref) =>
                          this.changeField(ref, "fixedemployee")
                        }
                        checked={this.state.fixedemployee == 1 ? true : false}
                        style={{
                          width: "18px",
                          height: "18px",
                          marginLeft: "5px",
                        }}
                      ></input>
                    </span>
                  )}

                  {/*   {" "}
                  <input
                    type="button"
                    name="schbut"
                    value={
                      this.state.savedbookdata.employee != ""
                        ? " Change..."
                        : " Select..."
                    }
                    className="add"
                    onClick={() => this.showEmployee(1)}
                  ></input> */}
                </div>
              </div>
            </div>
            {/* <div className={"row"}>
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 textright">
                Remark:
              </div>
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                <input
                  value={this.state.remark}
                  onChange={(ref) => this.changeField(ref, "remark")}
                />
              </div>
            </div> */}

            <div className="row">
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9 textcenter">
                <button className="submit" onClick={() => this.submit()}>
                  Submit
                </button>
              </div>
            </div>
            <div className="row">
              <div
                className="col-xs-12 col-sm-12 col-md-12 col-lg-12 textcenter postresult"
                style={{ width: "70%", marginTop: "10px" }}
              >
                {this.state.postresult}
              </div>
            </div>
          </div>
        </div>
        {this.state.mytoast.show ? MyToast(this.state.mytoast) : null}
      </div>
    );
  }
  setStartDate(val) {
    //  console.log(val);
    /*   let date = "";
      if (val == null) date = "";
      else {
        let _date = new Date(
          val.getFullYear() + "-" + (val.getMonth() + 1) + "-" + val.getDate()
        );
        date = _date;
        console.log(date);
      } */
    this.setState({
      periodno: val,
    },
      () => {
        this.getEmployeeData();
      });

  }
  ShowServiceList(val) {
    this.setState({
      servicelistshow: val,
      postresult: "",
      filteredservicelist: [],
      schservicetitle: "",
    });
  }
  selService(id, title) {
    this.setState({
      serviceid: id,
      servicetext: title,
      filteredservicelist: [],
      schservicetitle: "",
      servicelistshow: 0,
    });
  }
  searchService(e) {
    let val = e.target.value;
    let olddata = this.state.schservicetitle;
    this.setState({
      schservicetitle: val,
    });
    if (val == "") {
      this.setState({
        filteredservicelist: [],
      });
    }
    if (olddata.length < val.length && val.length >= 2) {
      val = val.trim();
      console.log(val);
      console.log(this.state.servicelist);
      let filteredservicelist = [];

      this.state.servicelist.forEach((element, j) => {
        /*    let _item = JSON.stringify(element)
          .toLowerCase()
          .includes(val.toLowerCase()); */
        //
        let _item = element.child.find((item3) =>
          item3.title.toLowerCase().includes(val.toLowerCase())
        );

        if (_item != null) {
          console.log(_item);
          filteredservicelist.push(_item);
          /*    id = _item.id;
          servicetext = _item.title;
          minutes = _item.minutes; */
        }
      });
      if (filteredservicelist.length > 0) {
        this.setState({
          filteredservicelist: filteredservicelist,
          // totalpage: result.num,
        });
      }
    }
  }
  /* changeTime(e)
{

  this.setState({
        fromtime: "",
      });
} */
  changeField(e, field) {
    let val = e.target.value;

    if (field == "username")
      this.setState({
        username: val,
      });
    else if (field == "employee")
      this.setState({
        employeeusername: val,
      });
    else if (field == "haveinsurance") {
      val = "";
      if (e.target.checked) {
        val = 1;
      } else {
        val = 0;
      }
      this.setState({
        haveinsurance: val,
      });
    }
    else if (field == "service") {
      if (val == "") {
        this.setState({
          selectedservice: "",
          serviceid: "",
          servicetext: "",
          serviceminutes: 0,
        });
      } else {
        let id = "";
        let servicetext = "";

        let minutes = 0;

        this.state.servicelist.forEach((element, j) => {
          let _item = element.child.find((item3) => item3.id === val);

          if (_item != null) {
            id = _item.id;
            servicetext = _item.title;
            minutes = _item.minutes;
          }
        });

        /*  let arr = val.split("|");
        let index = arr[0] * 1;
        let index2 = arr[1] * 1;
        //   const text = e.target.options[e.target.selectedIndex].text;
        let id = this.state.servicelist[index].child[index2].id;
        let servicetext = this.state.servicelist[index].child[index2].title;

        let minutes = this.state.servicelist[index].child[index2].minutes * 1; */

        this.setState({
          selectedservice: val,
          serviceid: id,
          servicetext: servicetext,
          serviceminutes: minutes,
        });
      }
    } else if (field == "telephone") {
      this.setState({
        telephone: val,
      });
    } else if (field == "remark")
      this.setState({
        remark: val,
      });
    else if (field == "fromhour") {
      let fromminute = this.state.fromminute;
      if (
        val * 1 == this.state.minhour &&
        fromminute * 1 < this.state.minminute
      )
        fromminute = this.state.minminute;
      if (
        val * 1 == this.state.maxhour &&
        fromminute * 1 >= this.state.maxminute
      )
        fromminute = this.state.maxminute;

      this.setState({
        fromhour: val,
        fromminute: fromminute,
      });
    } else if (field == "fromminute")
      this.setState({
        fromminute: val,
      });
    else if (field == "firstname") {
      this.setState({
        firstname: val,
      });
    } else if (field == "lastname") {
      this.setState({
        lastname: val,
      });
    } else if (field == "telephone") {
      this.setState({
        telephone: val,
      });
    } else if (field == "fixedemployee") {
      val = "";
      if (e.target.checked) {
        val = 1;
      } else {
        val = 0;
      }

      this.setState({
        fixedemployee: val,
      });
    }
  }

  submit() {
    const { myusername, ukey, myusertype } = this.props;

    if (myusertype >= 1 && this.state.storeid != "") {
      MyToastShow(
        this,
        2,
        "You are not a customer, need logout for visit this page",
        200,
        120,
        "fail",
        "addemployee"
      );
      return;
    }
    //console.log(this.state.pages);
    // document.getElementById("result2").style.color = "#000000";
    //
    const now = getPeriodnoFromDate(new Date());

    let fromtime = this.state.fromhour + ":" + this.state.fromminute;
    let worktimefrom = this.state.minhour + ":" + this.state.minminute;
    let worktimeto = this.state.maxhour + ":" + this.state.maxminute;

    let error = "";
    if (this.state.serviceid == "") error = "Please select service";
    else if (this.state.periodno == "") error = "Please select date";
    //else if((this.state.fromminute+this.state.fromhour*60)<(this.state.minminute+this.state.minhour*60)||(this.state.fromminute+this.state.fromhour*60)>(this.state.maxminute+this.state.maxhour*60))
    else if (
      new Date("2023-1-1 " + fromtime) < new Date("2023-1-1 " + worktimefrom) ||
      new Date("2023-1-1 " + fromtime) > new Date("2023-1-1 " + worktimeto)
    ) {
      error =
        "Please select time between " + worktimefrom + " - " + worktimeto + "";
    }

    let periodno = this.state.periodno;
    if (periodno != "") {
      periodno =
        periodno.getFullYear() +
        "-" +
        (periodno.getMonth() + 1) +
        "-" +
        periodno.getDate();
    }

    const _now = new Date();
    const tomorrow = new Date(_now.getTime() + 24 * 60 * 60 * 1000);
    let yuetime = new Date(periodno + " " + fromtime);

 
    if (yuetime <= tomorrow) {
      error = "Please don't select date within 24 hours";
    }
    if (error == "") {
      if (this.state.isguest == 1) {
        /*    if (getPeriodnoFromDate(this.state.periodno, "yyyyMMdd") <=now)
             error = "Please don't select date before or today"; */
      /*   const _now = new Date();
        const tomorrow = new Date(_now.getTime() + 24 * 60 * 60 * 1000);
        let yuetime = new Date(periodno + " " + fromtime);
     
        if (yuetime <= tomorrow) {
          error = "Please don't select date within 24 hours";
        } */
        if (this.state.firstname == "") error = "Please fill in first name";
        else if (this.state.telephone == "") error = "Please fill in telephone";
      }
      else {
        if (getPeriodnoFromDate(this.state.periodno, "yyyyMMdd") < now)
          error = "Please don't select date before";
      }
    }
    if (error != "") {
      MyToastShow(this, 2, error, 200, 120, "fail", "addemployee");
      return;
    }
    this.setState({
      postresult: "Waiting....",
    });
    // document.getElementById("result2").innerHTML = "正在提交....";
    //   let dianscope = this.state.selectedbranch;

    let customer = this.props.myusername;
    if (this.state.isguest == 1) customer = "";
    let fixedemployee = this.state.fixedemployee;
    if (this.state.employeeusername == "") fixedemployee = 0;
    const postdata = {
      uid: myusername,
      //  editid: this.state.editid,
      isguest: this.state.isguest,
      storeid: this.state.storeid,
      customer: customer,

      serviceid: this.state.serviceid,
      employee: this.state.employeeusername,
      haveinsurance: this.state.haveinsurance,
      periodno: periodno,
      fromtime: fromtime,
      // hours: this.state.bookdata.mins,
      //serviceminutes: this.state.serviceminutes,
      //  birthday: birthday,

      firstname: this.state.firstname,
      lastname: this.state.lastname,
      telephone: this.state.telephone,
      remark: this.state.remark,
      fixedemployee: fixedemployee,
    };


    const that = this;
    //return;
    /* if (this.state.bookdata.nickname == "") error = "Please select time";
    else if (this.state.telephone === "") error = "Please fill in telephone";
    else if (this.state.serviceid == "") error = "Please select service"; */
    // else if (this.state.password == "") error = "Please fill in password";
    // else if (dianscope == ",") error = "Please select branch";
    //document.getElementById("result2").style.color = "red";
    if (error !== "") {
      this.setState({
        postresult: error,
      });
    } else {
      $.ajax({
        type: "POST",
        dataType: "json",
        url:
          AJAROOT +
          "/postaja/savexx.aspx?action=customeraddbook&u=" +
          myusername +
          "&ukey=" +
          encodeURIComponent(ukey) +
          "",
        data: JSON.stringify(postdata),
      })
        .done(function (res) {
          var r = res.r;
          //  console.log(res);
          if (r === 1) {
            //  this.postresult = "登陆成功！";
            MyToastShow(
              that,
              2,
              "Successfully! ",
              150,
              200,
              "success",
              "addbranch"
            );
            that.setState({
              submited: true,
              postresult:
                "Successfully! Thanks for you support! Please wait our check. ",
            });

            //  that.props.history.push("/user/book/list");
          } else {
            MyToastShow(that, 2, res.s, 200, 120, "fail", "addemployee");
            //document.getElementById("result2").style.color = "red";
            that.setState({
              postresult: res.s,
            });

            //  document.getElementById("result2").innerHTML = msg;
          }
        })
        .fail(function (xhr, textStatus, errorThrown) { });
    }
  }
  /*  getBranchData(myusername, ukey) {
    var geturl =
      AJAROOT +
      "/getaja/xx.aspx?u=" +
      myusername +
      "&ukey=" +
      ukey +
      "&action=branchlist";

    axios.get(geturl).then((res) => {
      const result = res.data;
      /*  let selectedbranch = ",";
      result.list.forEach((element, j) => {
        selectedbranch = selectedbranch + element.username + ",";
      }); 
      //  let groupright = eval(result.groupright);

      this.setState({
        //  selectedbranch: selectedbranch,
        branchlist: result.list,
        dianusername: result.list[result.list.length - 1].username,
      });  
    });
  } */

  componentDidMount() {
    const { myusername, myusertype, ukey } = this.props;
    var { id, store } = queryString.parse(this.props.location.search);
    let isguest = 0;
    if (myusername == "undefined") myusername = "";
    if (myusername == undefined) myusername = "";
    if (myusername == null) myusername = "";
    let storeid = "";

    if (myusername == "") isguest = 1;

    if (id == undefined) id = "";
    if (id == null) id = "";
    if (store == undefined) store = "";
    if (store == null) store = "";
    if (store != "") {
      storeid = store;
    }
    var title = "Add Booking";
    if (id != "") {
      title = "Edit Booking";
    }
    document.title = title;
    if (myusertype >= 1 && storeid != "") {
      this.setState({
        needlogout: true,
      });
    }

    const today = new Date()
    const tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1)

    this.setState(
      {
        title: title,
        storeid: storeid,
        isguest: isguest,
        //    periodno: tomorrow,
      },
      () => {
        let _username = myusername;
        if (isguest == 1) _username = store;

        if (isguest == 0) getUserIndexData(this, _username, ukey);
        //if (id != "") this.getData(myusername, encodeURIComponent(ukey), id);
        this.getService(_username, encodeURIComponent(ukey));
        //  this.getEmployeeData(_username, encodeURIComponent(ukey));
        this.getEarestandlasttime(_username, encodeURIComponent(ukey));
      }
    );
    // this.getBranchData(myusername, encodeURIComponent(ukey));

    //  MyToastShow(this, 200, "Successfully! ", 150, 80, "success", "addgroup");
  }
  getEarestandlasttime(myusername, ukey) {
    var geturl =
      AJAROOT +
      "/getaja/xx.aspx?u=" +
      myusername +
      "&ukey=" +
      ukey +
      "&isguest=" +
      this.state.isguest +
      "&action=earestandlasttime";

    axios.get(geturl).then((res) => {
      let hours = [];
      const result = res.data;

      let minhour = result.minhour;

      let maxhour = result.maxhour;
      for (let j = minhour; j <= maxhour; j++) {
        let hour = j;
        if (j < 10) hour = "0" + j;
        hours.push(hour);
      }
      this.setState({
        hours: hours,
        minhour: minhour,
        maxhour: maxhour,
        minminute: result.minminute,
        maxminute: result.maxminute,
        fromhour: minhour,
        fromminute: result.minminute,
      });
      // this.setState({}); /**/
    });
  }
  getService(myusername, ukey) {
    var geturl =
      AJAROOT +
      "/getaja/xx.aspx?u=" +
      myusername +
      "&ukey=" +
      ukey +
      "&customer=1&dian=" + this.state.storeid + "&isguest=" +
      this.state.isguest +
      "&action=bookservicelist";

    axios.get(geturl).then((res) => {
      const result = res.data;

      this.setState({
        servicelist: result.list,
      });
      // this.setState({}); /**/
    });
  }
  getEmployeeData() {
    const { myusername, myusertype, ukey } = this.props;

    let _username = myusername;
    if (this.state.isguest == 1) _username = this.state.storeid;

    let _periodno = getPeriodnoFromDate(this.state.periodno, "yyyyMMdd");

    var geturl =
      AJAROOT +
      "/getaja/xx.aspx?u=" +
      _username +
      "&ukey=" +
      ukey +
      "&opened=1&periodno=" + _periodno + "&dian=" + this.state.storeid + "&action=youxiaoemployeelist";
    console.log(geturl);

    axios.get(geturl).then((res) => {
      const result = res.data;
      console.log(result);
      this.setState({
        employeelist: result.list,
      });
      // this.setState({});
    });
  }
  getData(myusername, ukey, id) {
    var geturl =
      AJAROOT +
      "/getaja/xx.aspx?u=" +
      myusername +
      "&ukey=" +
      ukey +
      "&action=bookinfo&id=" +
      id +
      "";

    axios.get(geturl).then((res) => {
      const result = res.data;

      let bookdata = this.state.bookdata;
      bookdata.employee = result.employee;
      bookdata.nickname = result.nickname;
      bookdata.periodno = result.periodno;
      bookdata.fromtime = result.fromtime;
      bookdata.totime = result.totime;
      //  let groupright = eval(result.groupright);

      this.setState({
        editid: result.id,
        username: result.username,
        serviceid: result.serviceid,
        telephone: result.telephone,
        // selectedemployee: result.employee,
        //  employeeusername: result.employee,
        usertel: result.telephone,
        //  fromtime: result.fromtime,
        // totime: result.totime,
        //  periodno: result.periodno,
        serviceminutes: result.minutes,
        remark: result.remark,
        status: result.status,
        tip: result.tip,
        bookdata: bookdata,
        savedbookdata: bookdata,
        oldemployee: result.nickname,
        oldperiodno: result.periodno,
        oldfromtime: result.fromtime,
        oldtotime: result.totime,
      });
      // this.setState({}); /**/
    });
  }
  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }
  componentWillUpdate() { }
  componentDidUpdate() { }
}

const mapStateToProps = (state) => {
  return {
    myusername: state.getIn(["gdata", "username"]),
    myusertype: state.getIn(["gdata", "usertype"]),
    myusergrade: state.getIn(["gdata", "usergrade"]),
    ukey: state.getIn(["gdata", "ukey"]),
    zsxm: state.getIn(["gdata", "zsxm"]),
  };
};

export default connect(mapStateToProps, null)(Index);
